import { useTranslation } from 'react-i18next';

import { Head } from 'components/common';
import { Data, Intro, Maxmizing, Operating } from 'components/publishers/adserver';
import MANUALS from 'constants/manuals';
import Layout from 'layout/index';

const AdserverPage = () => {
  const { t } = useTranslation();

  return (
    <Layout starterConfig={{ mediaKit: MANUALS.monetize, contactHash: '#publishers' }}>
      <Head title="Adserver" description={t('publishers.adserver.intro.description')} />
      <Intro mediaKit={MANUALS.monetize} />
      <Data />
      <Operating />
      <Maxmizing />
    </Layout>
  );
};

export default AdserverPage;
